var render = function render(){var _vm=this,_c=_vm._self._c;return _c('b-row',[_c('b-col',{staticClass:"d-flex align-items-stretch",attrs:{"cols":"12","lg":"12"}},[_c('b-card',{directives:[{name:"hotkey",rawName:"v-hotkey",value:(_vm.keymap),expression:"keymap"}],staticClass:"mb-4 w-100 h-100"},[(_vm.configEmails.length === 0)?_c('div',[_c('div',{staticClass:"d-flex align-items-center justify-content-center mt-5"},[_c('span',[_vm._v("No se ha asignado un email para esta empresa, por favor agregue una configuración para el evío de correos.")])]),_c('div',{staticClass:"d-flex align-items-center justify-content-center mt-4"},[_c('b-button',{directives:[{name:"b-popover",rawName:"v-b-popover.hover.top",value:('Presione alt+a'),expression:"'Presione alt+a'",modifiers:{"hover":true,"top":true}}],staticClass:"d-flex align-items-center",attrs:{"variant":"primary","disabled":!_vm.permisos.includes('config-mail-crear')},on:{"click":_vm.addRegister}},[_c('feather',{staticClass:"mr-2 text-blue-active",attrs:{"type":"plus","size":"1rem"}}),_vm._v(" Agregar email a esta empresa ")],1)],1)]):_c('div',[_c('div',{staticClass:"table-responsive mt-3"},[_c('b-table',{staticClass:"mb-0 hover-table text-nowrap",attrs:{"fields":_vm.fields,"items":_vm.configEmails,"tbody-tr-class":_vm.rowClass,"borderless":"","responsive":"","head-variant":"light"},scopedSlots:_vm._u([{key:"cell(tipo)",fn:function(data){return [_c('span',{class:`badge bg-light-success ${
                                    _vm.Theme === 'dark' && 'text-gray'
                                }`},[_vm._v(" "+_vm._s(data.value)+" ")])]}},{key:"cell(acciones)",fn:function(data){return [(
                                    _vm.permisos.includes('config-mail-editar')
                                )?_c('feather',{directives:[{name:"b-popover",rawName:"v-b-popover.hover.top",value:('Editar'),expression:"'Editar'",modifiers:{"hover":true,"top":true}}],staticClass:"mr-2",attrs:{"type":"edit","role":"button"},on:{"click":function($event){return _vm.$router.push({
                                        name: 'Editar configuración emial',
                                        params: {
                                            configuracion_email_id:
                                                data.item.id
                                        }
                                    })}}}):_c('feather',{directives:[{name:"b-popover",rawName:"v-b-popover.hover.top",value:('Editar'),expression:"'Editar'",modifiers:{"hover":true,"top":true}}],staticClass:"mr-2 text-secondary",attrs:{"type":"edit","role":"button"}}),_c('feather',{directives:[{name:"b-popover",rawName:"v-b-popover.hover.top",value:('Ver'),expression:"'Ver'",modifiers:{"hover":true,"top":true}}],staticClass:"mr-2",attrs:{"type":"eye","role":"button"},on:{"click":function($event){return _vm.$router.push({
                                        name: 'Ver configuración emial',
                                        params: {
                                            configuracion_email_id:
                                                data.item.id
                                        }
                                    })}}})]}}])})],1),_c('div',{staticClass:"p-3 d-flex align-items-center"},[_c('b-form-group',{staticClass:"mb-0",attrs:{"label":"Número de registros","label-for":"per-page-select","label-size":"sm","label-class":"fw-medium"}},[_c('b-form-select',{attrs:{"id":"per-page-select","options":_vm.pagination.pageOptions,"size":"sm"},on:{"change":function($event){return _vm.getConfigEmails('', 1, $event)}},model:{value:(_vm.pagination.perPage),callback:function ($$v) {_vm.$set(_vm.pagination, "perPage", $$v)},expression:"pagination.perPage"}})],1),_c('div',{staticClass:"ml-auto"},[_c('b-pagination',{staticClass:"my-0",attrs:{"first-number":"","last-number":"","size":"sm","total-rows":_vm.pagination.totalRows,"per-page":_vm.pagination.perPage,"align":"fill"},on:{"input":function($event){return _vm.getConfigEmails(
                                    '',
                                    $event,
                                    _vm.pagination.perPage
                                )}},model:{value:(_vm.pagination.currentPage),callback:function ($$v) {_vm.$set(_vm.pagination, "currentPage", $$v)},expression:"pagination.currentPage"}})],1)],1)])])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }