<template>
    <b-row>
        <b-col cols="12" lg="12" class="d-flex align-items-stretch">
            <b-card class="mb-4 w-100 h-100" v-hotkey="keymap">
                <div v-if="configEmails.length === 0">
                    <div
                        class="d-flex align-items-center justify-content-center mt-5"
                    >
                        <span
                            >No se ha asignado un email para esta empresa, por
                            favor agregue una configuración para el evío de
                            correos.</span
                        >
                    </div>
                    <div
                        class="d-flex align-items-center justify-content-center mt-4"
                    >
                        <b-button
                            variant="primary"
                            class="d-flex align-items-center"
                            v-b-popover.hover.top="'Presione alt+a'"
                            @click="addRegister"
                            :disabled="!permisos.includes('config-mail-crear')"
                        >
                            <feather
                                type="plus"
                                size="1rem"
                                class="mr-2 text-blue-active"
                            />
                            Agregar email a esta empresa
                        </b-button>
                    </div>
                </div>
                <div v-else>
                    <div class="table-responsive mt-3">
                        <b-table
                            :fields="fields"
                            :items="configEmails"
                            :tbody-tr-class="rowClass"
                            borderless
                            responsive
                            head-variant="light"
                            class="mb-0 hover-table text-nowrap"
                        >
                            <template #cell(tipo)="data">
                                <span
                                    :class="`badge bg-light-success ${
                                        Theme === 'dark' && 'text-gray'
                                    }`"
                                >
                                    {{ data.value }}
                                </span>
                            </template>
                            <template #cell(acciones)="data">
                                <feather
                                    type="edit"
                                    role="button"
                                    class="mr-2"
                                    v-b-popover.hover.top="'Editar'"
                                    v-if="
                                        permisos.includes('config-mail-editar')
                                    "
                                    @click="
                                        $router.push({
                                            name: 'Editar configuración emial',
                                            params: {
                                                configuracion_email_id:
                                                    data.item.id
                                            }
                                        })
                                    "
                                ></feather>
                                <feather
                                    type="edit"
                                    role="button"
                                    class="mr-2 text-secondary"
                                    v-b-popover.hover.top="'Editar'"
                                    v-else
                                ></feather>
                                <feather
                                    type="eye"
                                    role="button"
                                    class="mr-2"
                                    v-b-popover.hover.top="'Ver'"
                                    @click="
                                        $router.push({
                                            name: 'Ver configuración emial',
                                            params: {
                                                configuracion_email_id:
                                                    data.item.id
                                            }
                                        })
                                    "
                                ></feather>
                            </template>
                        </b-table>
                    </div>
                    <div class="p-3 d-flex align-items-center">
                        <b-form-group
                            label="Número de registros"
                            label-for="per-page-select"
                            label-size="sm"
                            label-class="fw-medium"
                            class="mb-0"
                        >
                            <b-form-select
                                id="per-page-select"
                                v-model="pagination.perPage"
                                :options="pagination.pageOptions"
                                @change="getConfigEmails('', 1, $event)"
                                size="sm"
                            ></b-form-select>
                        </b-form-group>
                        <div class="ml-auto">
                            <b-pagination
                                first-number
                                last-number
                                size="sm"
                                v-model="pagination.currentPage"
                                :total-rows="pagination.totalRows"
                                :per-page="pagination.perPage"
                                @input="
                                    getConfigEmails(
                                        '',
                                        $event,
                                        pagination.perPage
                                    )
                                "
                                align="fill"
                                class="my-0"
                            ></b-pagination>
                        </div>
                    </div>
                </div>
            </b-card>
        </b-col>
    </b-row>
</template>
<script>
import services from '@/boot/axios'
import { mapGetters, mapState } from 'vuex'
import { removeListenerCommands, addListenerCommands } from '@/utils/others'

const { API } = services

export default {
    name: 'ConfiguracionEmail',
    mounted() {
        addListenerCommands(this)
        this.getConfigEmails()
    },
    beforeDestroy() {
        removeListenerCommands(this)
    },
    data: () => ({
        fields: [
            { key: 'tipo', label: 'Tipo' },
            { key: 'email', label: 'Email' },
            { key: 'clase', label: 'Clase' },
            { key: 'acciones', label: 'Acciones' }
        ],
        configEmails: [],
        pagination: {
            totalRows: 0,
            currentPage: 1,
            perPage: 10,
            pageOptions: [10, 20, 30, 40, 50, 100]
        },
        search: ''
    }),
    methods: {
        showLog(data) {
            console.log(data)
        },
        searchData() {
            if (this.search.length >= 3) {
                this.getConfigEmails(this.search)
            } else if (!this.search) {
                this.getConfigEmails()
            }
        },
        showConfirm({ id, email }) {
            this.$bvModal
                .msgBoxConfirm(
                    `Usted está apunto de eliminar el banco "${email}", desea continuar con esta acción?`,
                    {
                        title: 'Advertencia!',
                        size: 'sm',
                        buttonSize: 'sm',
                        okVariant: 'danger',
                        okTitle: 'Continuar',
                        cancelTitle: 'Cancelar',
                        footerClass: 'p-2 d-flex justify-content-between',
                        hideHeaderClose: false,
                        centered: true,
                        headerBgVariant: 'danger',
                        headerClass: 'modal-header'
                    }
                )
                .then((value) => {
                    if (value) {
                        this.deleteConfigEmail(id)
                    }
                })
                .catch((err) => {
                    console.log(err)
                })
        },
        deleteConfigEmail(id) {
            API.POST({
                url: 'cartero/config-mail/crud',
                data: {
                    p_datajson: {
                        id
                    },
                    p_opc: 'DELETE',
                    auditoriajson: this.auditoriajson
                }
            })
                .then((response) => {
                    const {
                        data: {
                            status: { status, mensaje, mensaje_exception }
                        }
                    } = response
                    if (status === 'ok') {
                        this.getConfigEmails()
                        this.$bvModal
                            .msgBoxOk(mensaje || 'Eliminado correctamente', {
                                title: 'Exito!',
                                size: 'sm',
                                buttonSize: 'sm',
                                okVariant: 'success',
                                okTitle: 'Aceptar',
                                cancelTitle: 'Cancelar',
                                footerClass: 'p-2',
                                hideHeaderClose: false,
                                centered: true,
                                headerBgVariant: 'success',
                                headerClass: 'modal-header'
                            })
                            .then(() => {
                                // console.log(value);
                            })
                            .catch((err) => {
                                console.log(err)
                            })
                    } else {
                        this.$bvModal
                            .msgBoxOk(
                                mensaje ||
                                    mensaje_exception ||
                                    'Ha ocurrido un error!',
                                {
                                    title: 'Error!',
                                    size: 'sm',
                                    buttonSize: 'sm',
                                    okVariant: 'error',
                                    okTitle: 'Aceptar',
                                    cancelTitle: 'Cancelar',
                                    footerClass: 'p-2',
                                    hideHeaderClose: false,
                                    centered: true,
                                    headerBgVariant: 'error',
                                    headerClass: 'modal-header'
                                }
                            )
                            .then(() => {
                                // console.log(value);
                            })
                            .catch((err) => {
                                console.log(err)
                            })
                    }
                })
                .catch((error) => {
                    const { message } = error
                    this.$bvModal
                        .msgBoxOk(message || 'Ha ocurrido un error!', {
                            title: 'Error!',
                            size: 'sm',
                            buttonSize: 'sm',
                            okVariant: 'error',
                            okTitle: 'Aceptar',
                            cancelTitle: 'Cancelar',
                            footerClass: 'p-2',
                            hideHeaderClose: false,
                            centered: true,
                            headerBgVariant: 'error',
                            headerClass: 'modal-header'
                        })
                        .then(() => {
                            // console.log(value);
                        })
                        .catch((err) => {
                            console.log(err)
                        })
                })
        },
        getConfigEmails(term = '', page = 1, limit = this.pagination.perPage) {
            API.POST({
                url: 'cartero/config-mail/query',
                data: {
                    p_datajson: {
                        page,
                        term,
                        limit,
                        empresa_id: this.getUltimaEmpresa.id
                    },
                    p_opc: 'FILTER',
                    p_auditoriajson: this.auditoriajson
                }
            })
                .then((response) => {
                    const {
                        data: {
                            dato: { content, totalContent, size, number },
                            status: { status, mensaje, mensaje_exception }
                        }
                    } = response
                    if (status === 'ok') {
                        this.configEmails = content
                        this.pagination = {
                            ...this.pagination,
                            totalRows: totalContent,
                            perPage: size,
                            currentPage: number
                        }
                    } else {
                        this.$bvModal
                            .msgBoxOk(
                                mensaje ||
                                    mensaje_exception ||
                                    'Ha ocurrido un error!',
                                {
                                    title: 'Error!',
                                    size: 'sm',
                                    buttonSize: 'sm',
                                    okVariant: 'error',
                                    okTitle: 'Aceptar',
                                    cancelTitle: 'Cancelar',
                                    footerClass: 'p-2',
                                    hideHeaderClose: false,
                                    centered: true,
                                    headerBgVariant: 'error',
                                    headerClass: 'modal-header'
                                }
                            )
                            .then(() => {
                                // console.log(value);
                            })
                            .catch((err) => {
                                console.log(err)
                            })
                    }
                })
                .catch((error) => {
                    const { message } = error
                    this.$bvModal
                        .msgBoxOk(message || 'Ha ocurrido un error!', {
                            title: 'Error!',
                            size: 'sm',
                            buttonSize: 'sm',
                            okVariant: 'error',
                            okTitle: 'Aceptar',
                            cancelTitle: 'Cancelar',
                            footerClass: 'p-2',
                            hideHeaderClose: false,
                            centered: true,
                            headerBgVariant: 'error',
                            headerClass: 'modal-header'
                        })
                        .then(() => {
                            // console.log(value);
                        })
                        .catch((err) => {
                            console.log(err)
                        })
                })
        },
        addRegister() {
            const CONFIG_EMAIL_ID =
                this.configEmails.length > 0 ? this.configEmails[0].id : null
            if (CONFIG_EMAIL_ID) {
                this.$router.push(
                    `/home/general/generales/configuracion-email/editar-configuracion-email/${CONFIG_EMAIL_ID}`
                )
            } else {
                this.$router.push(
                    '/home/general/generales/configuracion-email/crear-configuracion-email'
                )
            }
        },
        rowClass(item, type) {
            if (!item || type !== 'row') return
            if (item.status === 'active') return 'active'
        }
    },
    computed: {
        keymap() {
            if (
                navigator.platform.toLowerCase().includes('mac') ||
                navigator.platform.toLowerCase().includes('macintosh')
            ) {
                return {
                    'alt+a': this.addRegister
                }
            } else if (
                navigator.platform.toLowerCase().includes('win') ||
                navigator.platform.toLowerCase().includes('windows')
            ) {
                return {
                    'alt+a': this.addRegister
                }
            } else {
                return {}
            }
        },
        auditoriajson() {
            const json = { ...this.getAuditoriaJson, modulo_id: 33 }
            return json
        },
        permisos() {
            return this.getPermisos.permisos_authorities
        },
        ...mapGetters('usuario', [
            'getUserData',
            'getAuditoriaJson',
            'getPermisos'
        ]),
        ...mapGetters('empresas', ['getUltimaEmpresa']),
        ...mapState(['Theme'])
    }
}
</script>

<style></style>
